import la from '../LazyComponent';

const SalesWorkStation = la(() => import('@/pages/SalesWorkStation'));
const RingCentralCallback = la(() => import('@/pages/RingCentralCallback'));
const DatabaseSearch = la(() => import('@/pages/DatabaseSearch'));
const SalesWorkStationV2 = la(() => import('@/pages/SWSv2Menu/SalesWorkStation'));
const DatabaseSearchV2 = la(() => import('@/pages/SWSv2Menu/DatabaseSearch'));

const SalesWorkStationRoutes = [
  {
    index: true,
    path: '/salework-station/dashboard',
    element: SalesWorkStation,
    name: 'menu.SaleWorkStation',
    meta: {
      key: 'menu.sws.dashboard',
      mCompatible: true,
    },
  },
  {
    path: '/salework-station/databaseSearch',
    element: DatabaseSearch,
    name: 'SEARCH',
    meta: {
      key: 'menu.sws.general.search',
    },
  },
  {
    index: true,
    path: '/ring-central/callback',
    element: RingCentralCallback,
    meta: {
      key: 'menu.sws.dashboard',
    },
  },
  {
    index: true,
    path: '/salework-station/dashboardV2',
    element: SalesWorkStationV2,
    name: 'menu.SaleWorkStation',
    meta: {
      key: 'sws.v2.dashboard',
      mCompatible: true,
    },
  },
  {
    path: '/salework-station/databaseSearchV2',
    element: DatabaseSearchV2,
    name: 'SEARCH',
    meta: {
      key: 'sws.v2.general.search',
    },
  },
];
export default SalesWorkStationRoutes;
