import lazy from '../LazyComponent';

const ReferralLink = lazy(() => import('@/pages/SWSv2Menu/ReferralLinks'));

const linkRoutes = [
  {
    path: '/referralLink',
    element: ReferralLink,
    name: 'menu.referralLink',
    meta: {
      key: 'sws.v2.referral-links',
    },
  },
];

export default linkRoutes;
